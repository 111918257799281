import React from "react"
import Layout from "../components/Layout"
import { InsurancePage } from "../components/InsurancePage"
import { RichText } from "../components/PricingPage/sections/InsuranceWidget/utils"

interface IInsuranceData {
  pageContext: {
    activeInsurances: RichText[]
  }
}

export default function InsurancePartners(props: IInsuranceData) {
  return (
    <Layout>
      <InsurancePage activeInsurances={props.pageContext.activeInsurances} />
    </Layout>
  )
}
