import styled from "styled-components"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { ContentSection } from "./LandingPageStyles"
import { InsuranceCarrierSelect } from "../PricingPage/sections/InsuranceWidget/InsuranceCarrierSelect"
import { InsuranceCarrierSelectV2 } from "../PricingPage/sections/InsuranceWidget/InsuranceCarrierSelectV2"
import { colors } from "../themes/colors"
import { RichText } from "../PricingPage/sections/InsuranceWidget/utils"
import { useRolloutFlag } from "../../store/rolloutFlag"
import { useEffect } from "react"

const Container = styled(ContentSection)<{ hasLogo?: boolean }>`
  display: flex;
  max-width: 1216px;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  padding: ${({ hasLogo }) => (hasLogo ? "96px 0 64px 0" : "0 0 96px 0")};
  box-sizing: border-box;

  @media (max-width: 834px) {
    padding: ${({ hasLogo }) =>
      hasLogo ? "64px 24px 32px 24px" : "0 24px 64px 24px"};
  }
`

const Title = styled.h1`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;

  @media (max-width: 834px) {
    font-size: 28px;
    line-height: 30px;
  }
`

export const SubTitle = styled.div`
  color: #4d515a;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 20px auto 32px auto;
  max-width: 768px;

  @media (max-width: 834px) {
    width: 100%;
    max-width: fit-content;
    margin: 20px 0 40px 0;
  }
`

const GridWrapper = styled.div`
  display: grid;
  grid-template-areas: "logo-1 logo-2 logo-3 logo-4 logo-5";
  width: 100%;
  padding: 0 15px 0 15px;
  margin-bottom: 40px;
  gap: 10px;

  @media (max-width: 768px) {
    padding: 0;
    grid-template-areas:
      "logo-1 logo-1 logo-3 logo-3 logo-5 logo-5"
      "logo-4 logo-4 logo-4 logo-2 logo-2 logo-2";
  }
`

const MainImageWrapper = styled.div`
  width: 100%;

  & > div {
    width: 100% !important;
    height: 516px !important;

    @media (max-width: 834px) {
      height: 400px !important;
    }

    @media (max-width: 600px) {
      height: 225px !important;
    }
  }
`

const MainImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`

const LogoImage = styled.img`
  height: 64px;

  @media (max-width: 480px) {
    height: 56px;
  }
`
export const WaitListText = styled.p`
  font-family: Poppins;
  color: ${colors.c_grey[800]};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  margin-bottom: 40px;

  a {
    color: ${colors.c_secondary[600]};
    font-size: 12px;
    text-decoration-line: underline;
  }

  @media (max-width: 480px) {
    margin-bottom: 30px;
  }
`

export interface HeaderWithInsuranceLogosProps {
  slice: {
    primary: {
      heading: {
        text: string
      }
      copy: {
        text: string
      }
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
      banner_image: {
        gatsbyImageData: HTMLImageElement
        alt: string
      }
      waitlist_link: {
        url: string
      }
    }
    items: Array<{
      insurance_logo: {
        gatsbyImageData: HTMLImageElement
        alt: string
      }
    }>
  }
  activeInsurances: RichText[]
}

const HeaderWithInsuranceLogos = ({
  slice,
  activeInsurances,
}: HeaderWithInsuranceLogosProps) => {
  const { heading, copy, banner_image, waitlist_link } = slice.primary
  const { items } = slice
  const main_image = getImage(banner_image)
  const has_logo = items.length > 0
  const { flag, loadFlag } = useRolloutFlag()

  useEffect(() => {
    loadFlag("insurance_rte_release_state_provider_config")
  }, [])

  return (
    <Container hasLogo={has_logo}>
      {(heading.text || copy.text) && (
        <>
          {heading.text && <Title>{heading.text}</Title>}
          {copy.text && <SubTitle>{copy.text}</SubTitle>}
        </>
      )}
      {flag?.insurance_rte_release_onboarding ? (
        <InsuranceCarrierSelectV2 />
      ) : (
        <InsuranceCarrierSelect insuranceCsv={activeInsurances} />
      )}
      {items.length > 0 && (
        <GridWrapper>
          {items.map((item, index) => (
            <div style={{ gridArea: `logo-${index + 1}` }}>
              <LogoImage
                src={getImage(item.insurance_logo)?.images?.fallback?.src}
                alt={item.insurance_logo.alt || heading.text}
                loading="eager"
                key={index}
                style={{ overflow: "unset" }}
              />
            </div>
          ))}
        </GridWrapper>
      )}
      <WaitListText>
        Don’t see your state or insurance carrier?{" "}
        <a href={waitlist_link.url} target="_blank">
          Join waitlist
        </a>
      </WaitListText>
      {main_image && (
        <MainImageWrapper>
          <MainImage
            src={getImage(main_image)?.images?.fallback?.src}
            alt={banner_image.alt || heading.text}
            loading="eager"
          />
        </MainImageWrapper>
      )}
    </Container>
  )
}

export const query = graphql`
  fragment PageHeaderWithInsuranceLogosSlice on PrismicPageDataBodyHeaderWithInsuranceLogos {
    slice_type
    primary {
      copy {
        text
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      heading {
        text
      }
      banner_image {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED)
        alt
      }
    }
    items {
      insurance_logo {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED)
        alt
      }
    }
  }
`
export default HeaderWithInsuranceLogos
