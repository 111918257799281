import styled from "styled-components"
import {
  Backdrop,
  Buttons,
  StyledImage,
  SubscriptionPopup,
} from "../InsuranceModals/StateInsurancePopup/styled"
import subscriptionclose from "../../images/subscriptionclose.svg"
import { ModalButton } from "../InsuranceModals/StateInsurancePopup/styled"

const ModalText = styled.p`
  padding: 8px 40px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`

export const InsuranceModal = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean
  closeModal(): void
}) =>
  isModalOpen ? (
    <>
      <Backdrop isOpen={isModalOpen} onClick={closeModal} />
      <SubscriptionPopup isOpen={isModalOpen}>
        <div>
          <StyledImage src={subscriptionclose} onClick={closeModal} />
          <h4 style={{ textAlign: "left" }}>
            What does your $30/mo subscription cost include?
          </h4>
          <ModalText>
            This fee helps cover what it costs to keep our platform up and
            running — including regular maintenance of our online tools and
            unlimited messaging.
          </ModalText>
          <ModalText>
            It gives you reliable access to features like easy online
            scheduling, session reminders, messaging with your therapist and
            care team, billing and account support, goal and progress tracking,
            and our knowledge resources.
          </ModalText>
        </div>
        <Buttons>
          <ModalButton onClick={closeModal}>Close</ModalButton>
        </Buttons>
      </SubscriptionPopup>
    </>
  ) : null
